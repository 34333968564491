import UIPopover from 'UIComponents/tooltip/UIPopover';
import styled, { css, keyframes } from 'styled-components';
import { rgba } from 'UIComponents/core/Color';
import { THUNDERDOME } from 'HubStyleTokens/colors';
const pulseAnimation = keyframes(["0%{box-shadow:0 0 0 0 ", ";}70%{box-shadow:0 0 0 6px ", ";}100%{box-shadow:0 0 0 0 ", ";}"], rgba(THUNDERDOME, 0.7), rgba(THUNDERDOME, 0), rgba(THUNDERDOME, 0));
const minimizedPopoverMixin = css(["animation:", " 2s 0.2s infinite;background-color:#e7e9f8 !important;padding:5px;border-radius:100px !important;width:250px !important;.uiPopoverHeader{border-radius:100px;padding:6px 14px;h5{font-size:14px;margin-top:1px;}h5 + h5{text-overflow:ellipsis;text-wrap:nowrap;width:200px;overflow:hidden;}}.uiPopoverArrow{display:none;}[aria-label='Close']{display:none;}"], pulseAnimation);
const MinimizablePopover = styled(UIPopover).withConfig({
  displayName: "MinimizablePopover",
  componentId: "di97zx-0"
})(["", ";"], ({
  minimize
}) => minimize && minimizedPopoverMixin);
export { MinimizablePopover };