import { Map as ImmutableMap } from 'immutable';
import isDevelopment from './development';
import * as global from './global';
let DISABLE_CACHED = false;
const reportingDataModule = line => line.split('reporting-data/static-1.51434/js')[1].split('.js')[0];
const staticArchiveModule = line => {
  const arr = line.split('.hubspot/static-archive/')[1].split('/');
  arr.splice(1, 2);
  return arr.join('/').split('.js')[0];
};
const getModule = () => {
  if (isDevelopment()) {
    try {
      // @ts-expect-error remove this logic after data regression testing/cli is removed.
      const line = new Error().stack.split('\n')[3];
      return line.indexOf('.hubspot') !== -1 ? staticArchiveModule(line) : reportingDataModule(line);
    } catch (e) {
      return `err_module_from_stack`;
    }
  }
  return null;
};
let caches = [];
const resetAll = () => {
  caches.forEach(cache => cache.reset());
  caches = [];
};

/**
 * Value symbol
 *
 * @constant
 */
const Value = Symbol('@@value@@');

/**
 * Stat action types
 *
 * @constant
 */
const actions = {
  HIT: 'hit',
  MISS: 'miss',
  BUST: 'bust'
};

/**
 * Empty stats record
 *
 * @constant
 */
const emptyStats = ImmutableMap({
  [actions.HIT]: 0,
  [actions.MISS]: 0,
  [actions.BUST]: 0
});

/**
 * Resolve map key from arguments
 *
 * @param {...any} args Input arguments
 * @returns {string[]} Map keys
 */
const getKeys = (...args) => args.length ? [...args, Value] : [Value];
const initGlobalCache = () => {
  if (isDevelopment()) {
    let cachesDev = ImmutableMap({});
    const debug = {
      add: (key, fn) => {
        cachesDev = cachesDev.has(key) ? cachesDev.set(`${key}_${cachesDev.count()}`, fn) : cachesDev.set(key, fn);
      },
      read: () => cachesDev.map(cachedFn => cachedFn.get()),
      readNonEmpty: () => debug.read().filter(cache => !cache.isEmpty())
    };
    global.initialize();
    global.set('cached', debug);
  }
};

/**
 * Created a cached/memoized function with stat tracking
 *
 * @param {string} key Key to identify the cache
 * @param {function} fn Function to cache/memoize
 * @param {number} [timeout=null] Time to live for cached value
 * @return {function} Cached/memoized function
 */
const cached = (key, fn, timeout = null) => {
  // delete once all cached calls are keyed
  if (typeof key === 'function') {
    timeout = fn;
    fn = key;
    key = getModule();
  } else {
    key = key && typeof key === 'string' ? `${getModule()} #${key}` : 'UNKEYED_CACHE';
  }
  let stats = ImmutableMap();
  let cache = ImmutableMap();
  let timeouts = ImmutableMap();

  /**
   * Bust cache and reset stats
   *
   * @returns {void}
   */
  const reset = () => {
    cache = ImmutableMap();
    stats = ImmutableMap();
    timeouts.valueSeq().forEach(timeoutId => {
      clearTimeout(timeoutId);
    });
  };

  /**
   * Stat tracker for hits, misses, and busts
   *
   * @param {string} action Stat description
   * @param {...any} args Input arguments
   * @return {void}
   */
  const count = (action, ...args) => {
    const keys = getKeys(...args);
    if (!stats.hasIn(keys)) {
      stats = stats.setIn(keys, emptyStats);
    }
    stats = stats.updateIn([...keys, action], counter => counter + 1);
  };

  /**
   * Clear timeout
   *
   * @param {...any} args Input arguments
   * @return {void}
   */
  const clear = (...args) => {
    const keys = getKeys(...args);
    if (timeouts.hasIn(keys)) {
      clearTimeout(timeouts.getIn(keys));
      timeouts = timeouts.deleteIn(keys);
    }
  };

  /**
   * Bust cached value
   *
   * @param {...any} args Input arguments
   * @return {void}
   */
  const bust = (...args) => {
    const keys = getKeys(...args);
    if (cache.hasIn(keys)) {
      count(actions.BUST, ...args);
      cache = cache.deleteIn(keys);
    }
  };

  /**
   * Cached/memoized function
   *
   * @param {...any} args Input arguments
   * @return {any}
   */
  const cachedFunction = (...args) => {
    if (DISABLE_CACHED) {
      return fn(...args);
    }
    const keys = getKeys(...args);
    if (!cache.hasIn(keys)) {
      count(actions.MISS, ...args);
      cache = cache.setIn(keys, fn(...args));
      if (timeout === 0) {
        bust(...args);
      } else if (timeout !== null) {
        clear(...args);
        timeouts = timeouts.setIn(keys, setTimeout(() => {
          clear(...args);
          bust(...args);
        }, timeout));
      }
    } else {
      count(actions.HIT, ...args);
    }
    return cache.getIn(keys);
  };
  cachedFunction.bust = bust;
  cachedFunction.reset = reset;
  cachedFunction.stats = () => stats;
  cachedFunction.get = () => cache;

  // TODO: find better way to handle no-arg collisions

  if (isDevelopment()) {
    if (!global.get('cached')) {
      initGlobalCache();
    }
    global.get('cached').add(key, cachedFunction);
  }
  caches.push(cachedFunction);
  return cachedFunction;
};
cached.Value = Value;
cached.enable = () => DISABLE_CACHED = false;
cached.disable = () => DISABLE_CACHED = true;
cached.resetAll = resetAll;
export default cached;